import React from "react";
import { LogoImage, flagImage, flageImage } from "@/data";
import Solxk from "@/images/logo-white.png";
import { Link } from "gatsby";

const Footer = () => {
  const { light } = LogoImage;
  return (
    <footer className="footer_1">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-sm-6 col-md-5">
            <aside className="widget aboutwidget">
              <a href="/">
                <img src={Solxk} alt="" width={35} />
              </a>

              <p>
                Wir sind darauf spezialisiert, außergewöhnliche Weberlebnisse zu
                schaffen.Unser Team Excels, um maßgeschneiderte Lösungen zu
                liefern, um Ihr Geschäft zu treffen Bedürfnisse.Vom Design bis
                zum Einsatz sorgen wir für einen nahtlosen und effektive
                Webpräsenz.Kontaktieren Sie uns, um Ihre Digital zu erhöhen
                Fußabdruck.
              </p>
            </aside>
            <div>
              <Link to="https://Solxk.com/" style={{ marginRight: "10px" }}>
                <img src={flagImage.light} alt="" width={40} />
              </Link>

              <Link to="https://al.Solxk.com/">
                <img src={flageImage.light} alt="" width={35} />
              </Link>
            </div>
          </div>

          <div className="col-lg-4 col-sm-4 col-md-4">
            <aside className="widget contact_widgets">
              <h3 className="widget_title">Kontakt</h3>
              <p>
                Pristina 10
                <br />
                Kosovo
              </p>
              <p>P: 049 000 000</p>
              <p>
                E: <a href="#">needhelp@Solxk.com</a>
              </p>
            </aside>
          </div>
          <div className="col-lg-3 col-sm-2 col-md-3">
            <aside className="widget social_widget">
              <h3 className="widget_title">Sozial</h3>
              <ul>
                <li>
                  <a href="#">
                    <i className="fa fa-twitter"></i>Twitter
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-facebook-square"></i>Facebook
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-youtube-play"></i>Youtube
                  </a>
                </li>
              </ul>
            </aside>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-sm-12 text-center">
            <div className="copyright">
              © copyright {new Date().getFullYear()} von{" "}
              <a href="#">Solxk.com</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
